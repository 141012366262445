import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const MdxIcon = makeShortcode("MdxIcon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "this-is-the-roadmap-for-the-carbon-design-system-based-on-our-planned-design-and-development-path-it-is-a-list-of-our-priorities-in-various-broad-initiative-areas"
    }}>{`This is the roadmap for the Carbon Design System, based on our planned design and development path. It is a list of our priorities in various broad initiative areas.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Timeline</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Community contribution</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Accessibility</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Brand/theme</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Website</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Components</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Disclaimer</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <Row className="tile--group" mdxType="Row">
      <Column colMd="4" colLg="4" noGutterSm mdxType="Column">
    <ResourceCard subTitle="October 2019 planning issue" href="https://github.com/carbon-design-system/carbon/issues/3871" mdxType="ResourceCard">
          <MdxIcon name="github" mdxType="MdxIcon" />
  </ResourceCard>
      </Column>
    </Row>
    <h2 {...{
      "id": "timeline"
    }}>{`Timeline`}</h2>
    <p>{`This plan captures our work for our `}<strong parentName="p">{`October 2019`}</strong>{` release.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`September 2nd, 2019`}</strong>{`: Start release cycle`}</li>
      <li parentName="ul"><strong parentName="li">{`October 4th, 2019`}</strong>{`: Code freeze for the endgame`}</li>
      <li parentName="ul"><strong parentName="li">{`October 7th-11th, 2019`}</strong>{`: Release Candidate cuts and smoke tests`}</li>
      <li parentName="ul"><strong parentName="li">{`October 15th, 2019`}</strong>{`: Release/Endgame done`}</li>
    </ul>
    <h2 {...{
      "id": "community-contribution"
    }}>{`Community contribution`}</h2>
    <h4 {...{
      "id": "primary-goals"
    }}>{`Primary goals`}</h4>
    <ul>
      <li parentName="ul">{`Hold a contribution summit (late September)`}</li>
      <li parentName="ul">{`Get alignment, validation and technical point of view for our proposed contribution model`}</li>
      <li parentName="ul">{`Operational Contributor License Agreement (CLA)) needs to go up on the site`}</li>
    </ul>
    <h4 {...{
      "id": "deferred-goals"
    }}>{`Deferred goals`}</h4>
    <ul>
      <li parentName="ul">{`High fidelity designs for community contribution experience on Carbon site`}</li>
    </ul>
    <h2 {...{
      "id": "accessibility"
    }}>{`Accessibility`}</h2>
    <h4 {...{
      "id": "primary-goals-1"
    }}>{`Primary goals`}</h4>
    <ul>
      <li parentName="ul">{`Clear high and medium priority issues in accessibility project backlog`}</li>
      <li parentName="ul">{`Provide general accessibility team advocacy and training throughout the project`}</li>
    </ul>
    <h4 {...{
      "id": "stretch-goals"
    }}>{`Stretch goals`}</h4>
    <ul>
      <li parentName="ul">{`Per component accessibility documentation`}</li>
      <li parentName="ul">{`Create a system to track/monitor turnaround time on a11y issues`}</li>
    </ul>
    <h2 {...{
      "id": "brandtheme"
    }}>{`Brand/theme`}</h2>
    <h4 {...{
      "id": "primary-goals-2"
    }}>{`Primary goals`}</h4>
    <ul>
      <li parentName="ul">{`Create a native, client-side search for our Gatsby theme`}</li>
      <li parentName="ul">{`Improve our website table component`}</li>
      <li parentName="ul">{`Vertical rhythm and spacing normalization in our theme`}</li>
      <li parentName="ul">{`Improve our video component accessibility`}</li>
    </ul>
    <h4 {...{
      "id": "stretch-goals-1"
    }}>{`Stretch goals`}</h4>
    <ul>
      <li parentName="ul">{`Create gated/protected paths for private data`}</li>
      <li parentName="ul">{`Enhance our image gallery `}<a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/gatsby-theme-carbon/issues/237"
        }}>{`#237`}</a></li>
    </ul>
    <h2 {...{
      "id": "website"
    }}>{`Website`}</h2>
    <h4 {...{
      "id": "primary-goals-3"
    }}>{`Primary goals`}</h4>
    <ul>
      <li parentName="ul">{`Content website content audit and strategy`}</li>
      <li parentName="ul">{`Comparative usage guidance audits`}</li>
      <li parentName="ul">{`Triage Patterns by quality and importance `}<a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-website/issues/189"
        }}>{`#189`}</a></li>
      <li parentName="ul">{`Patterns status overview (cupcake version)`}</li>
      <li parentName="ul">{`Get Cloud Integrations Patterns up on site `}<a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-website/issues/87"
        }}>{`#87`}</a></li>
      <li parentName="ul">{`Get Axure tool page up on site`}</li>
      <li parentName="ul">{`Reorganize and skin the grid demo and incorporate layout guidance`}</li>
      <li parentName="ul">{`Explore theme / variant switcher component display window`}</li>
      <li parentName="ul">{`Add Charts pages `}<a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-website/issues/208"
        }}>{`#208`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon-website/issues/239"
        }}>{`#239`}</a></li>
    </ul>
    <h4 {...{
      "id": "stretch-goals-2"
    }}>{`Stretch goals`}</h4>
    <ul>
      <li parentName="ul">{`Replace `}<inlineCode parentName="li">{`CarbonDocs`}</inlineCode>{` with mardkown/mdx`}</li>
      <li parentName="ul">{`Add Elements docs on website`}</li>
      <li parentName="ul">{`Add Sass docs on website`}</li>
      <li parentName="ul">{`MVP of integrated component page`}</li>
      <li parentName="ul">{`Wires and prototyping tertiary / on-page nav for Carbon site to better accommodate contribution vs. placing everything in ‘Experimental’`}</li>
    </ul>
    <h4 {...{
      "id": "deferred-goals-1"
    }}>{`Deferred goals`}</h4>
    <ul>
      <li parentName="ul">{`Use React instead of Vanilla in code examples`}</li>
      <li parentName="ul">{`Production guidelines to accompany mdx guidance`}</li>
    </ul>
    <h3 {...{
      "id": "tooling"
    }}>{`Tooling`}</h3>
    <h4 {...{
      "id": "primary-goals-4"
    }}>{`Primary goals`}</h4>
    <ul>
      <li parentName="ul">{`Shipping color palette V2 `}<a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon/issues/3904"
        }}>{`#3904`}</a></li>
      <li parentName="ul">{`IDL kit`}<ul parentName="li">
          <li parentName="ul">{`Completion / clean-up of any bugs from plugin`}</li>
          <li parentName="ul">{`Add pictograms when ready from dev`}</li>
        </ul></li>
      <li parentName="ul">{`Carbon kit get library swap from the new IDL and library kits`}</li>
    </ul>
    <h2 {...{
      "id": "components"
    }}>{`Components`}</h2>
    <h4 {...{
      "id": "primary-goals-5"
    }}>{`Primary goals`}</h4>
    <ul>
      <li parentName="ul">{`Determine priority of feature enhancements`}</li>
    </ul>
    <h2 {...{
      "id": "disclaimer"
    }}>{`Disclaimer`}</h2>
    <p>{`This roadmap is not a delivery commitment and is subject to change based on user needs and feedback.`}</p>
    <p><em parentName="p">{`Last updated 9/10/2019`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      